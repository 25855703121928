import React, { Component } from 'react';
import './FloatingMenu.scss';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import onClickOutside from "react-onclickoutside";

class FloatingMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    handleClickOutside = () => {
        this.setState({open: false});
    };

    toggleOpen = () => {
        this.setState({open: !this.state.open});
    }

    render() {
       return (
            <div className="floating-menu">
                <button onClick={() => this.toggleOpen()}><FontAwesomeIcon icon={faEllipsisV} /></button>
                {this.state.open && 
                <ul>
                    {this.props.actions.map((action, index) => (
                        <li key={index}>
                            <FontAwesomeIcon icon={action.icon} />
                            <button onClick={() => {action.method(); this.toggleOpen();} }>{action.label}</button>
                        </li>
                    ))}
                </ul>}
            </div>
        );
    }
}

export default onClickOutside(FloatingMenu);