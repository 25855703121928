import /*React,*/ { Component } from 'react';
import './Loading.scss';
//import { faCog } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Queue extends Component {
    render() {
        //<div><FontAwesomeIcon icon={faCog} spin={true}></FontAwesomeIcon></div>
        return null;
    }
}