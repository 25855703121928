import React, { Component } from 'react';
import AppHome from './pages/appHome/AppHome';
import SiteHome from './pages/siteHome/SiteHome';
import ProjectPage from './pages/projectPage/ProjectPage';
import OrgSettings from './pages/orgSettings/OrgSettings';
import UserSettings from './pages/userSettings/UserSettings';
import EnvPage from './pages/envPage/EnvPage';
import { Route, Switch , withRouter, Redirect } from 'react-router-dom';
import Login from './pages/login/Login';
import Features from './pages/features/Features';
import { inject } from 'mobx-react';
import App from './App';
import ProjectSettings from './pages/projectSettings/ProjectSettings';
import ReactGA from 'react-ga';

const routes = {
  main: [
    {
      path: '/',
      component: SiteHome,
      exact: true,
      redirectIfAuthorized: '/app'
    },
    {
      path: '/login',
      component: Login,
      redirectIfAuthorized: '/app'
    },
    {
      path: '/features',
      component: Features,
    },
    {
      path: '/app',
      component: App,
      redirectIfUnauthorized: '/login'
    },
    {
      path: '*',
      redirectIfAuthorized: '/app',
      redirectIfUnauthorized: '/'
    }
  ],
  app: [
    {
      path: '/app',
      exact: true,
      component: AppHome
    },
    {
      path: '/app/projects/:projectName',
      exact: true,
      component: ProjectPage
    },
    {
      path: '/app/projects/:projectName/settings',
      component: ProjectSettings,
      requireAdmin: true
    },
    {
      path: '/app/projects/:projectName/envs/:envName',
      component: EnvPage
    },
    {
      path: '/app/org/settings',
      component: OrgSettings,
      requireAdmin: true
    },
    {
      path: '/app/user/settings',
      component: UserSettings
    },
    {
      path: '/app/*',
      redirectIfAuthorized: '/app'
    }
  ]
};

class Routes extends Component {
  componentDidMount() {
    this.props.history.listen((location, _) => ReactGA.pageview(location.pathname));
    if (this.props.type !== 'app') return;

    const that = this;

    const loadState = (location, action) => {
      const path = location.pathname.split('/app')[1];
      const newLocationState = {
        project: path.startsWith('/projects/') ? path.split('/')[2] : null,
        env: path.includes('/envs/') ? path.split('/envs/')[1] : null
      }

      if (path.endsWith('/settings') && that.props.store.authStore.isAdmin) {
        that.props.store.orgStore.fetchUsers();
      }

      if (that.locationState.project !== newLocationState.project && newLocationState.project) {
        that.props.store.envStore.fetch(newLocationState.project);
      }

      if (that.locationState.env !== newLocationState.env) {
        that.props.store.resourceStore.updatesUnsubscribe();
        newLocationState.env && that.props.store.resourceStore.fetch(newLocationState.env);
      }

      that.locationState = newLocationState;
    };

    this.locationState = { project: null, env: null };
    loadState(this.props.location);
    this.unlisten = this.props.history.listen(loadState);
  }

  componentWillUnmount() {
    if (this.props.type === 'app') this.unlisten();
  }

  redirectRoute(route) {
    const redirect = this.props.store.authStore.user ? route.redirectIfAuthorized : route.redirectIfUnauthorized;
    if (redirect) return redirect;
    if (route.requireAdmin && this.props.store.authStore.user && !this.props.store.authStore.isAdmin) return '/app';
    return null;
  }

  render() {
    return (
      <div className={`${this.props.type}-route-view`}>
        <Switch>
            {routes[this.props.type].map((route, index) => 
              this.redirectRoute(route) ?
              (<Route 
                key={index}
                path={route.path}
                exact={route.exact}
                render={() => (<Redirect to={this.redirectRoute(route)}/>)}
                />) :
              (<Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />)
            )}
        </Switch>
      </div>
    );
  }
}

export default inject('store')(withRouter(Routes));