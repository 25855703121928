import React, { Component } from 'react';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';
import Routes from './routes';
import './Main.scss';
import { observer, inject } from "mobx-react";

const SiteHeader = inject('store')(withRouter((props) => {
    if (props.location.pathname.startsWith('/app')) {
      return null;
    }

    return (
      <header className="site-header">
        <h1><Link to="/">Quly</Link></h1> 
        <ul>
            <li><Link to="/features">Features</Link></li>
            { props.store.authStore.user ? 
              <li><Link to="/app">App</Link></li> :
              <li><Link to="/login">Login</Link></li>
            }
        </ul>
      </header>
    );
}));

class Main extends Component {
    render() {
      return (
        <div className="main">
          <Router>
            <SiteHeader />
            <Routes type="main" />
          </Router>
        </div>
      );
    }
}

export default inject('store')(observer(Main));