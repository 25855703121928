import React, { Component } from 'react';
import './Queue.scss';
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserImage from '../userImage/UserImage';

export default class Queue extends Component {
    render() {
        return (
            <div className="queue">
              {this.props.showIcon && <FontAwesomeIcon icon={faUserFriends} />}
              {this.props.queue.length > 0 ? 
              <ul>
                {this.props.queue.map((user, index) => (
                  <li key={index}>
                    <UserImage user={user.user} />
                  </li>
                ))}
              </ul> :
              <span>(Empty)</span>
              }
            </div>
        );
      }
}