import axios from 'axios';
import EntityStore from './entityStore';

class ProjectStore extends EntityStore {
    constructor(rootStore) {
        super(rootStore);
        this.rootStore.authStore.user && this.fetch();
    }

    get apiPrefix() {
        return 'projects';
    }

    get projects() {
        return this.entities;
    }

    async fetch() {
        const res = await axios.get('/projects');
        this.entities = res.data;
        this.state = 'ready';

        this.onFetch && this.onFetch();
        this.onFetch = null;
    }
}

export default ProjectStore;