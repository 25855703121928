import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { faCubes, faCube, faTrashAlt, faBan, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableText from '../../components/editableText/EditableText';
import './Env.scss';
import { withRouter } from 'react-router-dom';
import FloatingMenu from '../../components/floatingMenu/FloatingMenu';

class Env extends Component {
    render() {
        const envPath = `/app/projects/${this.props.match.params.projectName}/envs/${this.props.env.name}`,
              actions = [],
              enabled = this.props.env.status === 'enabled';

        if (this.props.editable) {
          actions.push({label: 'Delete', icon: faTrashAlt, method: this.props.delete(this.props.env)});

          if (enabled) {
            actions.push({label: 'Disable', icon: faBan, method: () => this.props.store.envStore.userAction(this.props.env, 'disable')});
          } else {
            actions.push({label: 'Enable', icon: faCheckCircle, method: () => this.props.store.envStore.userAction(this.props.env, 'enable')});
          }
        }
    
        return (
            <div className="env material-box">
                {actions.length && <FloatingMenu actions={actions} />}
                <EditableText editable={this.props.editable} text={this.props.env.caption} link={enabled && envPath} 
                              validate={(val) => this.props.store.envStore.fieldExists('caption', val)} 
                              valueEdited={(val) => this.props.update(this.props.env, {caption: val})} />
                {this.props.env.resourcesCount === 0 ? <div className="empty"><FontAwesomeIcon icon={faCubes} /></div> :
                <div className="count"><FontAwesomeIcon icon={faCube} /><span>{this.props.env.resourcesCount} Resources</span></div>}
                {!enabled && <h5 className="disabled">Disabled</h5>}
                {enabled && <button onClick={() => this.props.history.push(envPath)}>Open</button>}
            </div>
        );
    };
}

export default inject('store')(withRouter(observer(Env)));