import React, { Component } from 'react';
import './EditableText.scss';
import { faPen, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

export default class EditableText extends Component {
    constructor(props) {
        super(props);
        this.state = { editing: false, valid: true, value: null };
    }

    render() {
        const editToggle = () => {
            if (!this.state.valid) {
                return;
            }

            if (this.state.editing) {
                this.props.valueEdited(this.state.value);
                this.setState({editing: false, valid: true, value: null});
            } else {
                this.setState({editing: true, valid: true, value: null});
            }
        };

        return (
            <div className={`editable-text ${this.state.valid ? 'valid' : 'invalid'}`}>
                { this.props.editable ? 
                    (<div className="editable">
                        { this.state.editing ? <input type="text" defaultValue={this.props.text} 
                                                    onChange={(e) => e.target.value && this.setState({editing: true, valid: !this.props.validate || this.props.validate(e.target.value), value: e.target.value})}
                                                    onKeyDown={(e) => (e.key === 'Enter') && editToggle()} 
                                                    minLength={this.props.minLength || 2} maxLength={this.props.maxLength || 15} autoFocus /> : 
                                               (this.props.link ? <Link to={this.props.link}>{this.props.text}</Link> : <span>{this.props.text}</span>)}
                        {this.state.valid ? <button onClick={editToggle}><FontAwesomeIcon icon={this.state.editing ? faCheck : faPen}></FontAwesomeIcon></button> :
                         <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>}
                    </div>) :
                    (this.props.link ? <Link to={this.props.link}>{this.props.text}</Link> : <span>{this.props.text}</span>)
                }
            </div>
        );
    }
}