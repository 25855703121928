import React, { Component } from 'react';
import './AppHome.scss';
import { observer, inject } from "mobx-react";
import EntityList from '../../components/entityList/EntityList';
import Project from '../../components/project/Project';
import { faSitemap } from "@fortawesome/free-solid-svg-icons";

class AppHome extends Component {
    render() {
      return (
        <section className="app-home">
            <EntityList entityComponent={Project} entityStore={this.props.store.projectStore} 
                        entityProperty="project" entities={this.props.store.projectStore.projects}
                        newIcon={faSitemap} newCaption="Projects" editable={this.props.store.authStore.isAdmin} 
                        addEntity={async () => {
                          const newProject = await this.props.store.projectStore.addNew();
                          this.props.history.push(`/app/projects/${newProject.name}`);
                        }}  />
        </section>
      );
    }
}

export default inject('store')(observer(AppHome));