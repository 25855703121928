import { decorate, observable, action } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';

class AuthStore {
	user = null;
	rootStore = null;

    constructor(rootStore) {
		const userJSON = Cookies.get('quly-user');
		this.user = userJSON ? JSON.parse(userJSON.substr(2)) : null;
		this.rootStore = rootStore;
	}

	async logout() {
		await axios.post('/users/logout')
		Cookies.remove('quly-user');
		window.location = '/';
	}

	async login(provider) {
		const res = await axios.get(`/users/auth/${provider}`);
		window.location = res.data['redirect'];
	}

	get isAdmin() {
		return this.user.role === 'admin';
	}

	async loginDemoUser() {
		const res = await axios.get(`/users/login_demo`);
		window.location = res.data['redirect'];
	}

	async generateAPIToken() {
        const userRes = await axios.post(`/users/generate_api_token`);
		this.rootStore.authStore.user = userRes.data.user;
		this.rootStore.authStore.user.api_tokens[this.rootStore.authStore.user.api_tokens.length - 1] = {token: userRes.data.token, date: new Date(), new: true};
        return userRes.data.user;
    }

    async deleteAPIToken(tokenIndex) {
        const userRes = await axios.post(`/users/delete_api_token/${tokenIndex}`);
        this.rootStore.authStore.user = userRes.data;
        return userRes.data;
    }
}

export default decorate(AuthStore, {
	user: observable,
	logout: action,
	deleteAPIToken: action,
    generateAPIToken: action,
	loginDemoUser: action
});