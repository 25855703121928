import { decorate, observable, action } from 'mobx';
import axios from 'axios';

class EntityStore {
    entities = [];
    state = 'pending';
    rootStore = null;
    onFetch = null;
    updating = false;
    masterEntity = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get apiPrefix() {
        throw new Error('Abstract getter');
    }

    get masterStore() {
        return null;
    }

    get masterProperty() {
        return null;
    }

    fetchPath(masterEntityId) {
        return null;
    }

    async refreshEntity(entityId) {
        const res = await axios.get(`/${this.apiPrefix}/${entityId}`);
        return this._update(res.data);
    }

    async fetch(masterEntityName) {
        const that = this;
        this.entities = [];
        this.state = 'pending';
        if (this.masterStore.state === 'pending') {
            this.masterStore.onFetch = () => that.fetch(masterEntityName);
            return false;
        }

        const masterEntity = this.masterStore.findByName(masterEntityName);
        if (masterEntity) {
            this.masterEntity = masterEntity;
            const res = await axios.get(this.fetchPath(masterEntity._id));
            this.entities = res.data;
            this.state = 'ready';
        }

        this.onFetch && this.onFetch();
        this.onFetch = null;
        return true;
    }

    async addNew(props) {
        const res = await axios.post(`/${this.apiPrefix}`, props || {});
        return this._add(res.data);    
    }

    _add(newEntity) {
        if (this.entities.find(currEntity => currEntity._id === newEntity._id)) {
            return newEntity;
        }

        this.entities.unshift(newEntity);
        this.masterStore && this.masterStore.refreshEntity(newEntity[this.masterProperty]);
        return newEntity;
    }

    async delete(entity) {
        const res = await axios.delete(`/${this.apiPrefix}/${entity._id}`);
        return this._delete(res.data);
    }

    _delete(deletedEntity) {
        this.entities = this.entities.filter(currEntity => currEntity._id !== deletedEntity._id);
        return deletedEntity;
    }

    async update(entity, props) {
        const res = await axios.put(`/${this.apiPrefix}/${entity._id}`, props);
        return this._update(res.data);
    }

    async userAction(entity, action, updateData) {
        const res = await axios.post(`/${this.apiPrefix}/${entity._id}/${action}`, updateData);
        return this._update(res.data);
    }

    _update(updatedEntity) {
        let entityToUpdate = this.entities.find(currEntity => updatedEntity._id === currEntity._id);
        Object.assign(entityToUpdate, updatedEntity);
        return entityToUpdate;
    }

    fieldExists(field, val) {
        return !this.entities.find(currEntity => currEntity[field] === val.trim());
    }

    findByName(entityName) {
        return this.entities.find(entity => entity.name === entityName);
    }
}

export default decorate(EntityStore, {
    entities: observable,
    state: observable,
    userAction: action,
    fetch: action,
    addNew: action,
    update: action,
    delete: action
});