import React, { Component } from 'react';
import './Resource.scss';
import { faUser, faClock, faCube, faEye, faHandRock, faTimes, faTrashAlt, faEdit, faBan, faCheckCircle, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Queue from '../queue/Queue';
import { observer, inject } from "mobx-react";
import EditableText from '../../components/editableText/EditableText';
import FloatingMenu from '../../components/floatingMenu/FloatingMenu';
import Modal from 'react-modal';

const Header = observer((props) => {
  const actions = [];

  if (props.editable) {
    actions.push({label: 'Edit', icon: faEdit, method: () => props.openEdit()});
    actions.push({label: 'Delete', icon: faTrashAlt, method: props.delete(props.resource)});
  }

  if (props.resource.view_link) {
    actions.push({label: 'View', icon: faEye, method: () => {
      window.open(props.resource.view_link);
    }});
  }

  if (props.resource.deploy_link) {
    actions.push({label: 'Deploy', icon: faCloudUploadAlt, method: () => {
      window.open(props.resource.deploy_link);
    }});
  }

  if (props.resource.status === 'enabled') {
    actions.push({label: 'Disable', icon: faBan, method: props.disable});
  } else {
    actions.push({label: 'Enable', icon: faCheckCircle, method: props.enable});
  }

  actions.push({label: 'Take (f)', icon: faHandRock, method: props.take});
 
  return (
    <header>
      <EditableText editable={props.editable} text={props.resource.name} 
                    validate={props.fieldExists}
                    valueEdited={(val) => props.update(props.resource, {name: val})} />
      {actions.length ? <FloatingMenu actions={actions} /> : null}
    </header>
  )
});

function Footer(props) {
  return (
    <footer>
      {props.disabled ?
      (<div>Disabled</div>) :
      (<button onClick={props.action.event}>{props.action.caption}</button>)}
    </footer>
  );
}

function Empty() {
  return (
    <div className="empty">
      <FontAwesomeIcon icon={faCube} />
    </div>
  );
}

function User(props) {
  return ( 
    <div className="user">
      <FontAwesomeIcon icon={faUser} />
      <span>{props.user.name}</span>
    </div>
  );
}

function Time(props) {
  if (!props.time) {
    return null;
  }

  const formatDate = function(dateStr) {
    let date = new Date(dateStr);
    return (`${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`);
  };

  return ( 
    <div className="time">
      <FontAwesomeIcon icon={faClock} />
      <span>{formatDate(props.time.to)}</span>
      {props.isCurrentUser && <ul>
        <li><button className="grey" onClick={() => props.extend({hours: 1})}>+1</button></li>
        <li><button className="grey" onClick={() => props.extend({hours: 4})}>+4</button></li>
        <li><button className="grey" onClick={() => props.extend({hours: 8})}>+8</button></li>
      </ul>}
    </div>
  );
}

function Taken(props) {
  return (
    <div className="taken">
      <User user={props.user} />
      <Time isCurrentUser={props.isCurrentUser} time={props.time} extend={props.extend} />
      <Queue queue={props.queue} showIcon={true} />
    </div>
  );
}

class Resource extends Component {
  constructor(props) {
    super(props);
    this.state = { editOpen: false };
  }

  userAction = (action) => {
    return (updateData) => {
      this.props.store.resourceStore.userAction(this.props.resource, action, updateData);
    }
  }

  action = () => {
    const currentUser = this.props.store.authStore.user;

    if (this.props.resource.user) {
      if (this.props.resource.user._id === currentUser._id) {
        return { event: this.userAction('release'), caption: 'Release'}
      }

      return this.props.resource.queue.find(user => currentUser._id === user.user._id) ?
             { event: this.userAction('unqueue'), caption: 'Unqueue'} :
             { event: this.userAction('queue'), caption: 'Queue'};
    }

    return { event: () => {this.userAction('take')(); this.props.resource.deploy_link && window.open(this.props.resource.deploy_link);}, caption: 'Take'};
  }

  closeEdit(save, updateData) {
    if (save) {
      this.props.store.resourceStore.update(this.props.resource, updateData);
    }

    this.setState({editOpen: false});
  }

  render() {
    const isDisabled = this.props.resource.status === 'disabled';
    const isCurrentUser = this.props.resource.user && this.props.resource.user._id === this.props.store.authStore.user._id;
    
    return (
      <div className={`resource material-box ${isDisabled && 'disabled'} ${this.props.resource.comment && 'with-comment'} ${isCurrentUser && 'current-user'}`}>
        <Header resource={this.props.resource} editable={this.props.editable} 
                update={this.props.update} delete={this.props.delete}
                openEdit={() => this.setState({editOpen: true})}
                fieldExists={(val) => this.props.store.resourceStore.fieldExists('name', val)}
                disable={() => this.props.store.resourceStore.userAction(this.props.resource, 'disable')} 
                enable={() => this.props.store.resourceStore.userAction(this.props.resource, 'enable')}
                take={() => {this.props.store.resourceStore.userAction(this.props.resource, 'take'); this.props.resource.deploy_link && window.open(this.props.resource.deploy_link);}} />
        {this.props.resource.comment && <div className="comment">
          <span>{this.props.resource.comment}</span>
        </div>}
        {this.props.resource.user ? <Taken user={this.props.resource.user} 
                                        currentUser={this.props.store.authStore.user}
                                        isCurrentUser={isCurrentUser}
                                        time={this.props.resource.time} 
                                        queue={this.props.resource.queue} 
                                        extend={this.userAction('extend')} /> : <Empty />}
        <Footer action={this.action()} disabled={isDisabled}/>
        <Modal isOpen={this.state.editOpen} onRequestClose={() => this.closeEdit()} className="resource-edit-modal">
          <button className="close" onClick={() => this.closeEdit()}><FontAwesomeIcon icon={faTimes}/></button>
          <h4>Edit Resource</h4>
          <div><label>Name:</label><input maxLength="15" type="text" defaultValue={this.props.resource.name} onChange={(e) => this.setState({...this.state, name: e.target.value})}/></div>
          <div><label>Deploy Link:</label><input type="text" defaultValue={this.props.resource.deploy_link} onChange={(e) => this.setState({...this.state, deployLink: e.target.value})} /></div>
          <div><label>View Link:</label><input type="text" defaultValue={this.props.resource.view_link} onChange={(e) => this.setState({...this.state, viewLink: e.target.value})}  /></div>
          <div><label>Comment:</label><input type="text" defaultValue={this.props.resource.comment} maxLength="22" onChange={(e) => this.setState({...this.state, comment: e.target.value})}  /></div>
          <button className="save" onClick={() => this.closeEdit(true, { name: this.state.name, viewLink: this.state.viewLink, deployLink: this.state.deployLink, comment: this.state.comment})}>Save</button>
        </Modal>
      </div>
    );
  }
}

export default inject('store')(observer(Resource));
