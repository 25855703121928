import React, { Component } from 'react';
import './ProjectPage.scss';
import Loading from '../../components/loading/Loading';
import { observer, inject } from 'mobx-react';
import EntityList from '../../components/entityList/EntityList';
import Env from '../../components/env/Env';
import EditableText from '../../components/editableText/EditableText';
import { faChevronLeft, faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProjectPage extends Component {
  render() {
    if (this.props.store.projectStore.state === 'pending') {
        return <Loading />;
    }

    const projectName = this.props.match.params.projectName,
          project = this.props.store.projectStore.findByName(projectName),
          isEditable = this.props.store.authStore.isAdmin;

    if (!project) {
      return <span>Project Not Found!</span>;
    }

    return (
      <section className="project-page">
        <header>
          <button className="back" onClick={() => this.props.history.push('/app')}><FontAwesomeIcon icon={faChevronLeft} /></button>
          <EditableText editable={isEditable} text={project.caption} 
                        validate={(val) => this.props.store.projectStore.fieldExists('caption', val)} 
                        valueEdited={async (val) => {
                          const updatedProject = await this.props.store.projectStore.update(project, {caption: val});
                          this.props.history.push(`/app/projects/${updatedProject.name}`);
                        }} />
          {isEditable && <button className="settings" onClick={() => {this.props.history.push(`/app/projects/${projectName}/settings`)}}>Settings</button>}
        </header>
        <EntityList entityComponent={Env} entityStore={this.props.store.envStore} entityProperty="env" entities={this.props.store.envStore.envs} editable={true} 
                    addEntity={async () => {
                      const newEnv = await this.props.store.envStore.addNew({project: project._id});
                      this.props.history.push(`/app/projects/${projectName}/envs/${newEnv.name}`);
                    }}
                    newIcon={faCubes} newCaption="Environments" />
      </section>
    );
  }
}

export default inject('store')(observer(ProjectPage));
