import React, { Component } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import './App.scss';
import Select from 'react-select';
import UserImage from './components/userImage/UserImage';
import { faSitemap, faCubes, faUser, faUsersCog, faSignOutAlt, faTimes, faQuestionCircle, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer, inject } from "mobx-react";
import Routes from './routes';
import Modal from 'react-modal';
import FloatingMenu from './components/floatingMenu/FloatingMenu';
import ProjectImage from './images/project.png';
import EnvImage from './images/env.png';
import ResourceImage from './images/resource.png';

function User(props) {
  return (
    <div className="user">
      <UserImage user={props.user} />
      <span>{props.user.name}</span>
    </div>
  );
}

function Org(props) {
  return (
    <div className="org">
      {props.org.logo ? <img src={props.org.logo} alt={props.org.name}></img> :
      <span>{props.org.name}</span>}
    </div>
  );
}

function Header(props) {
  const history = useHistory();
  const actions = [];

  if (props.isAdmin) {
    actions.push({label: 'Org Settings', icon: faUsersCog, method: () => history.push('/app/org/settings')});
  }

  actions.push({label: 'User Settings', icon: faUser, method: () => history.push('/app/user/settings')});
  actions.push({label: 'Help', icon: faQuestionCircle, method: props.openHelp});
  actions.push({label: 'Logout', icon: faSignOutAlt, method: () => props.logout()});
  
  return (
    <header>
      <h1><Link to="/app">Quly</Link></h1>
      <Nav projects={props.projects} envs={props.envs}/>
      <FloatingMenu actions={actions} />
      <User user={props.user} />
      <Org org={props.user.org} />
      <i className="fr" />
    </header>
  );
}

function Nav(props) {
  const history = useHistory(),
      location = useLocation(),
      projectName = location.pathname.split('/')[3],
      envName = location.pathname.includes('/envs') && location.pathname.split('/')[5],
      envOptions = props.envs.map(env => {return {value: env.name ,label: env.caption}}),
      showEnvSelection = location.pathname.includes('/projects') && envOptions.length > 0;
  const projects = props.projects.map(proj => { return { value: proj.name, label: proj.caption}});

  return (
    <nav>
      <Link to="/app">Home</Link>
      <span>
        <FontAwesomeIcon icon={faSitemap} />
        <Select className="select-container" classNamePrefix="select" options={projects} 
                value={projects.find(option => option.value === projectName) || null} 
                onChange={(option) => {
                    history.push(`/app/projects/${option.value}`);
        }}/>
      </span>
      { showEnvSelection && <i /> }
      {showEnvSelection &&
        <span className="env-selector">
          <FontAwesomeIcon icon={faCubes} />
          <Select className="select-container" classNamePrefix="select" 
              options={envOptions} 
              value={envOptions.find(option => option.value === envName) || null} 
              onChange={(option) => {
                  history.push(`/app/projects/${projectName}/envs/${option.value}`);
          }}/>
        </span>}
    </nav>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { onboardingOpen: !localStorage.getItem('showedOnboarding') };
  }

  closeOnboarding = () => {
    localStorage.setItem('showedOnboarding', 'true');
    this.setState({onboardingOpen: false});
  }

  render() {
    return (
      <div className="app">
          <Router>
            <Header user={this.props.store.authStore.user} logout={this.props.store.authStore.logout} 
                projects={this.props.store.projectStore.projects} isAdmin={this.props.store.authStore.isAdmin}
                envs={this.props.store.envStore.envs} openHelp={() => this.setState({onboardingOpen: true})}
             />
            <Routes type="app" />
          </Router>
          <Modal isOpen={this.state.onboardingOpen} onRequestClose={() => this.closeOnboarding()} overlayClassName="onboarding-overlay" className="onboarding-modal">
              <button className="close" onClick={() => this.closeOnboarding()}><FontAwesomeIcon icon={faTimes}/></button>
              <h3>Welcome to Quly!</h3>
              <h4>Set up your project easily in 3 steps:</h4>
              <ul>
                <li><h5>1. Create a Project</h5><img src={ProjectImage} alt="project"></img></li>
                <li className="arrow"><FontAwesomeIcon icon={faChevronRight}/></li>
                <li><h5>2. Create an Environment</h5><img src={EnvImage} alt="environment"></img></li>
                <li className="arrow"><FontAwesomeIcon icon={faChevronRight}/></li>
                <li><h5>3. Create a Resource</h5><img src={ResourceImage} alt="resource"></img></li>
              </ul>
              <button className="start" onClick={() => this.closeOnboarding()}>Let's Start</button>
          </Modal>
      </div>
    );
  }
}

export default inject('store')(observer(App));