import React, { Component } from 'react';
import './ProjectSettings.scss';
import { observer, inject } from 'mobx-react';
import Loading from '../../components/loading/Loading';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import UserImage from '../../components/userImage/UserImage';

class ProjectSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {selectedUser: null, saved: false};
    }

    render() {
      if (this.props.store.projectStore.state === 'pending' || 
          this.props.store.orgStore.state === 'pending') {
        return <Loading />;
      }

      const projectName = this.props.match.params.projectName,
            project = this.props.store.projectStore.findByName(projectName);
      
      const users = this.props.store.orgStore.users.filter(currUser => !project.permissions.users.find(permUser => permUser._id === currUser._id))
                            .map(user => { return {value: user._id, label: user.name}});

      return (
        <section className="project-settings">
          <header>
            <button className="back" onClick={() => this.props.history.push(`/app/projects/${projectName}`)}><FontAwesomeIcon icon={faChevronLeft} /></button>
            <h3>{project.caption}</h3><span>/</span><h3 className="settings">Settings</h3>
          </header>
          <div className="type-selector">
              <input type="radio" name="users" defaultChecked={project.permissions.all_users} onChange={(e) => { project.permissions.all_users = e.target.checked; this.setState({...this.state, saved: false}); }} /> 
              <label>All users can view and edit environments / resources</label>
          </div>
          <div className="type-selector">
            <input type="radio" name="users" defaultChecked={!project.permissions.all_users} onChange={(e) => { project.permissions.all_users = !e.target.checked; this.setState({...this.state, saved: false}); }} /> 
            <label>Only <b>specific users</b> can view and edit environments / resources</label>
          </div>
          {!project.permissions.all_users && (<div className="permission-users">
            <div>
              <Select className="select-container" classNamePrefix="select" options={users} 
                                            value={users.find(option => option.value === (this.state.selectedUser && this.state.selectedUser._id)) || null}
                                            onChange={option => {
                                                this.setState({...this.state, selectedUser: this.props.store.orgStore.users.find(currUser => currUser._id === option.value)})
                                            }} />
              <button onClick={() => {
                if (!project.permissions.users.find(currUser => currUser._id === this.state.selectedUser._id)) {
                  project.permissions.users.push(this.state.selectedUser);
                  this.setState({...this.state, saved: false});
                }
              }}>Add</button>
            </div>
            <ul>
              {project.permissions.users.map((user, index) => (
                <li key={index}>
                  <UserImage user={user} />
                  <span>{user.name}</span>
                  <button onClick={() => project.permissions.users = project.permissions.users.filter(currUser => currUser._id !== user._id)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>)}
          <button onClick={async () => {
                    await this.props.store.projectStore.update(project, {permissions: project.permissions});
                    this.setState({...this.state, saved: true, selectedUser: null});
                }}>Save</button>
          {this.state.saved && <span>The settings were saved successfully!</span>}
        </section>
      );
    }
}

export default inject('store')(observer(ProjectSettings));
  