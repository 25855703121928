import EntityStore from './entityStore';

class EnvStore extends EntityStore {

    get envs() {
        return this.entities;
    }

    get apiPrefix() {
        return 'envs';
    }

    get masterStore() {
        return this.rootStore.projectStore;
    }

    get masterProperty() {
        return 'project';
    }

    fetchPath(masterEntityId) {
        return `/projects/${masterEntityId}/envs`;
    }
}

export default EnvStore;