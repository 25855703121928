import React, { Component } from 'react';
import './UserImage.scss';

export default class UserImage extends Component {
    render() {
        return (
            <div className="user-image">
              {this.props.user.image ? (
                <img src={this.props.user.image} alt={this.props.user.name} title={this.props.user.name}></img>
              ): (
                <span>{this.props.user.name.split(' ')[0][0] + this.props.user.name.split(' ')[1][0]}</span>
              )}
            </div>
        );
    }
}