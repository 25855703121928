import React, { Component } from 'react';
import './SiteHome.scss';
import LoginButton from '../../components/loginButton/LoginButton';
import HomeImage from '../../images/home.png';
import { observer, inject } from 'mobx-react';
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

class SiteHome extends Component {

    render() {
      return (
        <section className="site-home">
            <header>Resource Queue Management Simplified</header>
            <div className="content">
                <div className="content-container">
                  <div className="quick-start">
                      <h3>Get Started with One Simple Click</h3>
                      <div className="google"><LoginButton provider="google" text="signup"/></div>
                      <div><LoginButton provider="microsoft" text="signup"/></div>
                      <small>Want to check out Quly without signing up? Try our <button onClick={() =>
                      this.props.store.authStore.loginDemoUser() }>Demo</button></small>
                  </div>
                  <div className="features">
                    <h3>Featuring:</h3>
                    <ul>
                      <li><span className="ic"><FontAwesomeIcon icon={faSlack} /></span><span>Slack Integration</span></li>
                      <li><span className="ic"><FontAwesomeIcon icon={faCode} /></span><span>API</span></li>
                    </ul>
                    <div className="seperator"></div>
                    <Link to="/features">Explore more features...</Link>
                  </div>
                </div>
                <div className="image-container"><img src={HomeImage} alt="" /></div>
            </div>  
            <footer>
               <ul>
                 <li><a href="mailto:support@quly.io">Support</a></li>
                 <li><a href="mailto:contact@quly.io">Contact</a></li>
                 <li>|</li>
                 <li>© Quly 2020</li>
               </ul>
            </footer>
        </section>
      );
    }
}

export default inject('store')(observer(SiteHome));
  