import React, { Component } from 'react';
import LoginButton from '../../components/loginButton/LoginButton';
import './Login.scss';

export default class Login extends Component {
    render() {
        return (
            <section className="login">

                <div className="body">
                    <header>Login With One Simple Click</header>
                    <div className="google"><LoginButton provider="google" /></div>
                    <div><LoginButton provider="microsoft" /></div>
                </div>
                {new URLSearchParams(window.location.search).get('failed') &&
                 <div className="error">
                    Your last login attempt was unsuccessful
                    <small>Please verify you are using your organizational account</small>
                 </div> }
            </section>
        );
    }
}
  