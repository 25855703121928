import React, { Component } from 'react';
import './EntityList.scss';
import { observer, inject } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function NewEntity(props) {
    return (
        <div className={`new material-box ${props.loading && 'loading'}`}>
          <h3>{props.caption}</h3>
          <div><FontAwesomeIcon icon={props.icon} /></div>
          { props.canAdd && <button onClick={props.addNew}>+ Add New</button> }
        </div>
    );
}

class EntityList extends Component {
    constructor(props) {
        super(props);
        this.state = { deleteOpen: false, deleteEntity: null };
    }

    update = (entity, props) => {
        this.props.entityStore.update(entity, props);
    }

    delete = (entity) => {
        return () => {
            this.setState({deleteOpen: true, deleteEntity: entity}); 
        };
    }

    deletePerform = (perform) => {
        perform && this.props.entityStore.delete(this.state.deleteEntity);
        this.setState({deleteOpen: false, deleteEntity: null}); 
    }

    render() {
        const EntityComponent = this.props.entityComponent;

        return (
            <div className="entity-list">
                <ul>
                    <li><NewEntity addNew={this.props.addEntity} caption={this.props.newCaption} icon={this.props.newIcon} 
                                   canAdd={this.props.editable} loading={this.props.entityStore.state === 'pending'} /></li>
                    {this.props.entities.map((entity, index) => (
                    <li key={index}>
                        <EntityComponent {...{ [this.props.entityProperty]: entity, editable: this.props.editable, update: this.update, delete: this.delete }} />
                    </li>
                    ))}
                </ul>
                <Modal isOpen={this.state.deleteOpen} onRequestClose={() => this.deletePerform(false)} className="delete-modal">
                    <button className="close" onClick={() => this.deletePerform(false)}><FontAwesomeIcon icon={faTimes}/></button>
                    <h4>Are you sure you want to delete <span>{this.state.deleteEntity && (this.state.deleteEntity.caption || this.state.deleteEntity.name)}</span>?</h4>
                    <small>(The data cannot be restored)</small>
                    <div>
                        <button onClick={() => this.deletePerform(true)}>Yes</button>
                        <button onClick={() => this.deletePerform(false)}>No</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default inject('store')(observer(EntityList));