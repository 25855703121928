import React, { Component } from 'react';
import './Features.scss';
import { faChevronRight, faUserClock, faUserFriends, faUserCog, faUserLock, faTerminal, faHistory, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faClock, faHourglass, faStopCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectImage from '../../images/project.png';
import EnvImage from '../../images/env.png';
import ResourceImage from '../../images/resource.png';

export default class Features extends Component {

    render() {
      return (
        <section className="features-page">
          <div className="container">
            <article className="organization">
                <h4>Organize your resources efficiently</h4>
                <ul>
                  <li><h5>1. Create a Project</h5><img src={ProjectImage} alt="project"></img></li>
                  <li className="arrow"><FontAwesomeIcon icon={faChevronRight}/></li>
                  <li><h5>2. Create an Environment</h5><img src={EnvImage} alt="environment"></img></li>
                  <li className="arrow"><FontAwesomeIcon icon={faChevronRight}/></li>
                  <li><h5>3. Create a Resource</h5><img src={ResourceImage} alt="resource"></img></li>
                </ul>
            </article>
            <article className="smart-queue">
              <h4>Smart queue and release mechanism helps you track usage easily</h4>
              <ul className="list">
                <li><span className="ic"><FontAwesomeIcon icon={faClock} /></span><span>Take resources for a limited time slot</span></li>
                <li><span className="ic"><FontAwesomeIcon icon={faUserClock} /></span><span>Extend resource time slot</span></li>
                <li><span className="ic"><FontAwesomeIcon icon={faUserFriends} /></span><span>Queue for a resource when it's taken</span></li>
              </ul>
            </article>
            <article className="perms">
              <h4>Organization and project permissions help you manage access safely</h4>
              <ul className="list">
                <li><span className="ic"><FontAwesomeIcon icon={faUserCog} /></span><span>Manage user roles</span></li>
                <li><span className="ic"><FontAwesomeIcon icon={faUserLock} /></span><span>Manage permissions per project</span></li>
              </ul>
            </article>
            <article className="slack">
              <h4>Slack integration helps you always stay up to date</h4>
              <ul className="list">
              <li><span className="ic"><FontAwesomeIcon icon={faTerminal} /></span><span>Use slash commands to control your resources</span></li>
              <li><span className="ic"><FontAwesomeIcon icon={faStopwatch} /></span><span>Get reminders on resource allocations</span></li>
              <li><span className="ic"><FontAwesomeIcon icon={faHistory} /></span><span>View the resource actions performed</span></li>
              </ul>
            </article>
            <article className="api">
              <h4>Fully featured API helps you integrate with your CI/CD provider</h4>
              <ul className="list">
              <li><span className="ic"><FontAwesomeIcon icon={faStopCircle} /></span><span>Block actions on taken resources</span></li>
              <li><span className="ic"><FontAwesomeIcon icon={faHourglass} /></span><span>Check time left on resource</span></li>
              </ul>
            </article>
          </div>
        </section>
      );
    }
}
  