import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import UserImage from '../../components/userImage/UserImage';
import Select from 'react-select';
import Loading from '../../components/loading/Loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import Modal from 'react-modal';
import './OrgSettings.scss';

class OrgSettings extends Component {
    constructor(props) {
        super(props);
        this.state = { saved: false, users: {updated: [], deleted: []}, deleteOpen: false, userToDelete: null };
    }

    deleteUser(perform) {
        const user = this.state.userToDelete;
        if (perform) {
            this.props.store.orgStore.users = this.props.store.orgStore.users.filter(currUser => currUser._id !== user._id);
            this.setState({...this.state, saved: false, users: {...this.state.users, deleted: [...this.state.users.deleted, user], 
                                                                    updated: this.state.users.updated.filter(currUser => currUser._id !== user._id)}});
        }

        this.setState({...this.state, userToDelete: null, deleteOpen: false});
    }

    render() {
        if (this.props.store.orgStore.state === 'pending') {
            return <Loading />;
        }

        const roles = [{value: 'admin', label: 'Admin'}, {value: 'user', label: 'User'}];

        const projects = this.props.store.projectStore.projects.map(proj => { return { value: proj._id, label: proj.caption}});
        const envs = this.props.store.envStore.envs.map(env => { return { value: env._id, label: env.caption}});

        if (this.props.store.projectStore.state === 'ready' && this.props.store.authStore.user.org.slack ) {
            const defaultProject = this.props.store.projectStore.projects.find(entity => entity._id === this.props.store.authStore.user.org.slack.default_project);

            if (!this.props.store.envStore.masterEntity || this.props.store.envStore.masterEntity._id !== defaultProject._id) {
                this.props.store.envStore.fetch(defaultProject.name);
            }
        }

        return (
            <section className="org-settings">
                <h3>Organization Settings <small>({this.props.store.authStore.user.org.domain})</small></h3>
                <div className="name">
                    <label>Name:</label>
                    <input type="text" defaultValue={this.props.store.authStore.user.org.name} onChange={(e) => { this.props.store.authStore.user.org.name = e.target.value; this.setState({...this.state, saved: false})}}/>
                </div>
                <div className="logo">
                    <label>Logo:</label>
                    <input type="text" defaultValue={this.props.store.authStore.user.org.logo} onChange={(e) => { this.props.store.authStore.user.org.logo = e.target.value; this.setState({...this.state, saved: false})}}/>
                    <small>(Transparent .png, at least 60px x 30px)</small>
                </div>
                <div className="users">
                    <h4>Users ({this.props.store.orgStore.users.length})</h4>
                    <ul>
                        {this.props.store.orgStore.users.map((user, index) => (
                            <li key={index}>
                                <UserImage user={user} />
                                <label>{user.name}</label>
                                {user._id !== this.props.store.authStore.user._id ? 
                                    <span>
                                        <Select className="select-container" classNamePrefix="select" options={roles} 
                                                value={roles.find(option => option.value === user.role) || null} 
                                                onChange={option => {
                                                    user.role = option.value;
                                                    if (this.state.users.updated.includes(user)) return; 
                                                    this.setState({...this.state, saved: false, users: {...this.state.users, updated: [...this.state.users.updated, user]}});
                                                }} />
                                        <button onClick={() => {
                                            this.setState({...this.state, deleteOpen: true, userToDelete: user})
                                        }}>Delete</button>
                                    </span> :
                                    <span className="curr-user-role">{this.props.store.authStore.user.role}</span>
                                }
                            </li>
                        ))}
                    </ul>
                    <small>* Admin users can update organization settings, project settings and add / delete projects</small>
                </div>
                <div className="integrations">
                    <h4>Integrations</h4>
                    {
                        this.props.store.authStore.user.org.slack ? 
                        <div className="slack">
                            <h5><FontAwesomeIcon icon={faSlack}/>Slack</h5>
                            <div className="channel">
                                <label>Channel:</label>
                                <input type="text" defaultValue={this.props.store.authStore.user.org.slack.channel} onChange={(e) => { this.props.store.authStore.user.org.slack.channel = e.target.value; this.setState({...this.state, saved: false})}}/>
                            </div>
                            <div className="domain">
                                <label>Team Domain:</label>
                                <input type="text" defaultValue={this.props.store.authStore.user.org.slack.team_domain} onChange={(e) => { this.props.store.authStore.user.org.slack.team_domain = e.target.value; this.setState({...this.state, saved: false})}}/>
                            </div>
                            <div className="project">
                                <label>Default Project:</label>
                                <Select className="select-container" classNamePrefix="select" options={projects} 
                                        value={projects.find(option => option.value === this.props.store.authStore.user.org.slack.default_project) || null} 
                                        onChange={(option) => {
                                            this.props.store.authStore.user.org.slack.default_project = option.value; 
                                            this.props.store.envStore.fetch(this.props.store.projectStore.projects.find(entity => entity._id === option.value).name);
                                            this.setState({...this.state, saved: false});
                                }}/>
                            </div>
                            <div className="env">
                                <label>Default Environment:</label>
                                <Select className="select-container" classNamePrefix="select" options={envs} 
                                        value={envs.find(option => option.value === this.props.store.authStore.user.org.slack.default_env) || null} 
                                        onChange={(option) => {
                                            this.props.store.authStore.user.org.slack.default_env = option.value; this.setState({...this.state, saved: false});
                                }}/>
                            </div>
                        </div> :
                        <a href="https://slack.com/oauth/v2/authorize?client_id=81165887270.960688999973&scope=app_mentions:read,chat:write,commands,users:read,users:read.email,team:read&redirect_uri=https://www.quly.io/slack/auth_callback">
                            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                        </a>
                    }
                </div>
                <button onClick={async () => {
                    await this.props.store.orgStore.update(this.state.users);
                    this.setState({...this.state, saved: true, users: {updated: [], deleted: []}});
                }}>Save</button>
                {this.state.saved && <span>The settings were saved successfully!</span>}

                <Modal isOpen={this.state.deleteOpen} onRequestClose={() => this.deleteUser(false)} className="delete-modal">
                    <button className="close" onClick={() => this.deleteUser(false)}><FontAwesomeIcon icon={faTimes}/></button>
                    <h4>Are you sure you want to delete <span>{this.state.userToDelete && this.state.userToDelete.name}</span>?</h4>
                    <small>(The data cannot be restored)</small>
                    <div>
                        <button onClick={() => this.deleteUser(true)}>Yes</button>
                        <button onClick={() => this.deleteUser(false)}>No</button>
                    </div>
                </Modal>
            </section>
        );
    }
}

export default inject('store')(observer(OrgSettings));
  