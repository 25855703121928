import AuthStore from './authStore';
import OrgStore from './orgStore';
import ProjectStore from './projectStore';
import EnvStore from './envStore';
import ResourceStore from './resourceStore';

export default class RootStore {
    constructor() {
      this.authStore = new AuthStore(this);
      this.orgStore = new OrgStore(this);
      this.projectStore = new ProjectStore(this);
      this.envStore = new EnvStore(this);
      this.resourceStore = new ResourceStore(this);
    }
}