import React, { Component } from 'react';
import './LoginButton.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { inject } from 'mobx-react';

const providerIcons = {
    'google': faGoogle,
    'microsoft': faMicrosoft
}

class LoginButton extends Component {
    render() {
        return (
            <button className="login-button" onClick={() => this.props.store.authStore.login(this.props.provider)}>
                <FontAwesomeIcon icon={providerIcons[this.props.provider]} />
                <span>{this.props.text || 'login'} with {this.props.provider}</span>
            </button>
        );
    }
}

export default inject('store')(LoginButton);