import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import RootStore from './stores/rootStore';
import { Provider } from 'mobx-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Main from './Main';
import ReactGA from 'react-ga';
import { init as initSaola } from '@saola.ai/browser';

initSaola('quly-token');
ReactGA.initialize('UA-151657701-1');
axios.defaults.headers.common['CSRF-Token'] = Cookies.get('quly-csrf');

ReactDOM.render(<Provider store={new RootStore()}><Main /></Provider>, document.getElementById('root'));
