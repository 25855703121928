import EntityStore from './entityStore';
import io from 'socket.io-client';

class ResourceStore extends EntityStore {
    socket = null;
    updateTimer = null;

    get resources() {
        return this.entities;
    }

    get apiPrefix() {
        return 'resources';
    }

    get masterStore() {
        return this.rootStore.envStore;
    }

    get masterProperty() {
        return 'env';
    }

    async fetch(masterEntityName) {
        await super.fetch(masterEntityName) && this.updatesSubscribe(masterEntityName);
    }

    fetchPath(masterEntityId) {
        return `/envs/${masterEntityId}/resources`;
    }

    updatesSubscribe(envName) {
        this.socket = io.connect('/resources');
        this.socket.emit('joinEnv', { env: this.masterStore.findByName(envName)._id });

        this.socket.on('resourceAdded', (data) => this._add(data));
        this.socket.on('resourceUpdated', (data) => this._update(data));
        this.socket.on('resourceDeleted', (data) => this._delete(data));

        this.updateTimer = setInterval(() => {
            super.fetch(envName);
        }, 5 * 60 * 1000);
    }

    updatesUnsubscribe() {
        this.socket && this.socket.disconnect();
        clearInterval(this.updateTimer);
    }
}

export default ResourceStore;