import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import './UserSettings.scss';

class UserSettings extends Component {
    constructor(props) {
        super(props);
        this.state = { tokenDeleteOpen: false, tokenToDelete: null };
    }

    deleteToken(perform) {
        const tokenToDelete = this.state.tokenToDelete;
        if (perform) {
            this.props.store.authStore.deleteAPIToken(tokenToDelete);
        }

        this.setState({...this.state, tokenDeleteOpen: false, tokenToDelete: null});
    }



    render() {
        const formatDate = (dateStr) => { 
            let date = new Date(dateStr);
            return (`${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`);
        };

        return (
            <section className="user-settings">
                <h3>User Settings <small>({this.props.store.authStore.user.email})</small></h3>
                <div className="api-tokens">
                    <h4>API Tokens ({this.props.store.authStore.user.api_tokens.length})</h4>
                    <ul>
                        {this.props.store.authStore.user.api_tokens.map((token, index) => (
                            <li key={index}>
                                { token.new ? <input type="text" value={token.token} readOnly></input> : <span>{token.token}</span> }
                                <span>{formatDate(token.date)}</span>
                                <button onClick={() => {
                                    this.setState({...this.state, tokenDeleteOpen: true, tokenToDelete: index});
                                }}>Delete</button>
                            </li>
                        ))}
                    </ul>
                    {this.props.store.authStore.user.api_tokens.length > 0 && this.props.store.authStore.user.api_tokens[this.props.store.authStore.user.api_tokens.length - 1].new && <small>Please copy the created token - it will be shown only once.</small>}
                    <button onClick={() => this.props.store.authStore.generateAPIToken()}>Generate New Token</button>
                </div>

                <Modal isOpen={this.state.tokenDeleteOpen} onRequestClose={() => this.deleteToken(false)} className="delete-modal">
                    <button className="close" onClick={() => this.deleteToken(false)}><FontAwesomeIcon icon={faTimes}/></button>
                    <h4>Are you sure you want to delete token <span>#{this.state.tokenToDelete + 1}</span>?</h4>
                    <small>(The data cannot be restored)</small>
                    <div>
                        <button onClick={() => this.deleteToken(true)}>Yes</button>
                        <button onClick={() => this.deleteToken(false)}>No</button>
                    </div>
                </Modal>
            </section>
        );
    }
}

export default inject('store')(observer(UserSettings));
  