import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { faCubes, faCube, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableText from '../../components/editableText/EditableText';
import './Project.scss';
import { withRouter } from 'react-router-dom';
import FloatingMenu from '../../components/floatingMenu/FloatingMenu';

class Project extends Component {
    render() {
        const projectPath = `/app/projects/${this.props.project.name}`;
        const actions = [];

        if (this.props.editable) {
          actions.push({label: 'Delete', icon: faTrashAlt, method: this.props.delete(this.props.project)})
        }

        return (
            <div className="project material-box">
                {actions.length ? <FloatingMenu actions={actions} /> : null }
                <EditableText editable={this.props.editable} text={this.props.project.caption} link={projectPath} 
                              validate={(val) => this.props.store.projectStore.fieldExists('caption', val)} 
                              valueEdited={(val) => this.props.update(this.props.project, {caption: val})} />
                <div className="count"><FontAwesomeIcon icon={faCubes} /><span>{this.props.project.envsCount} Environments</span></div>
                <div className="count"><FontAwesomeIcon icon={faCube} /><span>{this.props.project.resourcesCount} Resources</span></div>
                <button onClick={() => this.props.history.push(projectPath)}>Open</button>
            </div>
        );
    };
}

export default inject('store')(withRouter(observer(Project)));