import { decorate, observable, action } from 'mobx';
import axios from 'axios';

class OrgStore {
    users = null;
    rootStore = null;
    state = 'pending';

    constructor(rootStore) {
		this.rootStore = rootStore;
	}

    async fetchUsers() {
        const res = await axios.get(`/orgs/${this.rootStore.authStore.user.org._id}/users`);
        this.users = res.data;
        this.state = 'ready';
    }

    async update(users) {
        const org = this.rootStore.authStore.user.org;
        const orgRes = await axios.put(`/orgs/${org._id}`,org);
        this.rootStore.authStore.user.org = orgRes.data;

        await axios.post(`/users/update_many`, users);
        return {success: true};
    }
}

export default decorate(OrgStore, {
	users: observable,
    fetchUsers: action,
    update: action,
    state: observable
});