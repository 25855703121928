import React, { Component } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './EnvPage.scss';
import Queue from '../../components/queue/Queue';
import EntityList from '../../components/entityList/EntityList';
import { observer, inject } from "mobx-react";
import Loading from '../../components/loading/Loading';
import Resource from '../../components/resource/Resource';
import EditableText from '../../components/editableText/EditableText';
import { faCube } from "@fortawesome/free-solid-svg-icons";

class EnvPage extends Component {
    render() {
        if (this.props.store.envStore.state === 'pending') {
            return <Loading />;
        }

        const projectName = this.props.match.params.projectName,
              project = this.props.store.projectStore.findByName(projectName),
              envName = this.props.match.params.envName,
              env = this.props.store.envStore.findByName(envName),
              currentUser = this.props.store.authStore.user,
              envEnabled = env.status === 'enabled';
        
        if (!env) {
            return <span>Environment Not Found!</span>;
        }
 
        return (
            <section className="env-page">
                <header>
                    <button className="back" onClick={() => this.props.history.push(`/app/projects/${project.name}`)}><FontAwesomeIcon icon={faChevronLeft} /></button>
                    <h3>{project.caption}</h3><span>/</span>
                    <EditableText editable={true} text={env.caption} 
                        validate={(val) => this.props.store.envStore.fieldExists('caption', val)} 
                        valueEdited={async (val) => {
                          const updatedEnv = await this.props.store.envStore.update(env, {caption: val});
                          this.props.history.push(`/app/projects/${project.name}/envs/${updatedEnv.name}`);
                        }} />
                    <div className="queue-box">
                        {this.props.store.resourceStore.state === 'ready' && envEnabled && !this.props.store.resourceStore.resources.find(resource => resource.status === 'enabled' && (!resource.user || resource.user._id === currentUser._id)) && !env.queue.find(usr => usr.user._id === currentUser._id) &&
                        <button onClick={() => this.props.store.envStore.userAction(env, 'queue')}>Queue for Any</button>}
                        {env.queue.length > 0 && <Queue queue={env.queue} />}
                        {env.queue.find(user => user.user._id === currentUser._id) &&
                        <button onClick={() => this.props.store.envStore.userAction(env, 'unqueue')}>Unqueue</button>}
                    </div>
                    <button className="enable" onClick={() => this.props.store.envStore.userAction(env, envEnabled ? 'disable' : 'enable')}>{envEnabled ? 'Disable' : 'Enable'}</button>
                </header>
                <EntityList entityComponent={Resource} entityStore={this.props.store.resourceStore} entityProperty="resource" 
                            entities={this.props.store.resourceStore.resources} newIcon={faCube} newCaption="Resources" editable={true}
                            addEntity={() => this.props.store.resourceStore.addNew({project: project._id, env: env._id})}  />
            </section>
        );
    }
}

export default inject('store')(observer(EnvPage));
